import * as React from 'react'
import { useState, useEffect } from 'react'
import { getAbilitazioneTastoChiuso } from '../../services/abilitaChiudi'
import {
  Stack,
  Grid,
  Typography,
  TextField,
  Tooltip,
  textarea,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Box,
  IconButton,
  ListItem,
  List,
  Chip,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SummarizeIcon from '@mui/icons-material/Summarize'
import UploadDocument from './UploadDocument'
import DeleteDocument from './DeleteDocument'
import { format } from 'date-fns'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { useQuery } from '@tanstack/react-query'
import { StyledButton } from '../../UI/StyledButton'
import ListItemText from '@mui/material/ListItemText'
import { useLocation } from 'react-router-dom'
import { StatiPratica } from '../../utils/StatiPratica'
import { titlesToolTip } from '../../utils/titlesToolTip'
import { getFoldersTree } from '../../services/getFoldersTree'
import { useTheme } from '@mui/material/styles'
import OnlyWithFunction, { isValidUser } from '../../OnlyWithFunctions'
import EsitoAdeguataVerificaSalvata from './EsitoAdeguataVerificaSalvata'
import EsitoAdeguataVerificaMedioRSalvata from './EsitoAdeguataVerificaMedioRSalvata'
import EsitoAdeguataVerifica from './EsitoAdeguataVerifica'
import EsitoAdeguataVerificaMedioR from './EsitoAdeguataVerificaMedioR'
import CampoNote from './CampoNote'
import { closePratica } from '../../services/closePratica'
import { getEsitoADV } from '../../services/getEsitoADV'
import ApprovazioneAdeguataVerifica from './ApprovazioneAdeguataVerifica'
import ApprovazioneAdeguataVerificaSalvata from './ApprovazioneAdeguataVerificaSalvata'
import { getNodeChildById } from '../../services/getNodeChildById'
import TextFieldCreateFolder from './TextFieldCreateFolder'
import PrintIcon from '@mui/icons-material/Print'
import { getApprovazioneADV } from '../../services/getApprovazioneADV'
import { getRiepilogoPdf } from '../../services/getRiepilogoPdf'
import { OpenPrintPreviewFromByteArray } from '../../utils/OpenPrintPreviewFromByteArray'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { downloadDocument } from '../../services/downloadDocument'
import { useLayoutEffect } from 'react'
import EvidenzaEsitoMonitoraggio from './EvidenzaEsitoMonitoraggio'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const AccordionDettaglio = ({
  isFetchedIndicatoriPratica,
  indicatoriPratica,
  openAdvAutomatica,
  openTableState,
  dettaglioPratica,
  idNodoPratica,
  clickDoc,
  refetchPraticheDettaglio,
  valueNDGModal,
  valueRelazioneModal,
  syncStatoRichiesta,
  refetchPostUpdateFolder,
}) => {
  const [dataNotAvailable, setDataNotAvailable] = useState(true)

  const [statoRichiesta, setStatoRichiesta] = useState(
    dettaglioPratica.entry?.properties['folder_model:statorichiesta'],
  )
  // console.log('accordiodettaglio', dettaglioPratica)
  const [openAdv, setOpenAdv] = useState(openTableState)
  const [expanded, setExpanded] = useState(true)
  const [expandedDet, setExpandedDet] = useState(false) //dettaglio accordion
  const [showButton, setShowButton] = useState(false)
  const [sintesi, setSintesi] = useState('')
  const [disable, setDisable] = React.useState(true)
  const [idDocumenti, setIdDocumenti] = useState(undefined)
  const [noteUtenteAutorizzativo, setNoteUtenteAutorizzativo] =
    React.useState('')
  const [loading, setLoading] = useState(false)
  const [documentsToView, setDocumentsToView] = useState([])
  const theme = useTheme()
  const location = useLocation()
  const [refetchPraticheAccordion, setRefetchPraticheAccordion] = useState(
    refetchPostUpdateFolder ? refetchPostUpdateFolder : false,
  )
  const [refetchInterval, setRefetchInterval] = useState(0)
  const [refetchIntervalSezioneGialla, setRefetchIntervalSezioneGialla] =
    useState(0)
  const [fileToDownload, setFileToDownload] = useState(undefined)
  const [tipoInserimento, setTipoInserimento] = useState(undefined)
  const [openModal, setOpenModal] = useState(false);

  const [refreshListaDocValue, setRefreshListaDocValue] = useState(
    Number.MIN_SAFE_INTEGER,
  )
  const refreshListaDoc = () => {
    setRefreshListaDocValue(refreshListaDocValue + 1)
  }

  const handleDownloadFile = (event, fileId, fileName) => {
    //debugger
    event.preventDefault()
    downloadDocument(fileId, true).then(res =>
      setFileToDownload({ title: fileName, document: res }),
    )
  }
  //recupero(se presente) sezione gialla precedentemente salvata.
  const {
    data: sezioneGiallaSalvata,
    isFetching: isFetchingSezioneGiallaSalvata,
    isLoading: isLoadingSezioneGiallaSalvata,
    isFetched: isFetchedSezioneGiallaSalvata,
  } = useQuery(
    ['sezioneGiallaSalvata', idNodoPratica],
    () => getEsitoADV(idNodoPratica),
    { refetchInterval: refetchIntervalSezioneGialla },
  )
  const attivitaSvolteList = sezioneGiallaSalvata?.attivitaSvolteList || [];
  console.log(attivitaSvolteList)


  const { data: dataAbilitazioneTastoChiuso,
    isFetching: isDataFetching,
    isLoading: isDataLoading } = useQuery(
      ['boolean-disabled-tasto-chiuso', idNodoPratica],
      () => getAbilitazioneTastoChiuso(idNodoPratica), { refetchOnMount: 'always' }
    )

  const {
    data: dataDettaglioPratica,
    isFetching: isFetchingDettaglioPratica,
    isLoading: isLoadingDettaglioPratica,
    isFetched: isFetchedDettaglioPratica,
    refetch: refetchDettaglioPratica,
  } = useQuery(
    ['dettaglio_pratica', idNodoPratica],
    () => getNodeChildById(idNodoPratica),
    { refetchOnMount: 'always' },
  )

  const {
    data: dataCartelleDocumenti,
    isFetching: isFetchingCartelleDocumenti,
    isLoading: isLoadingCartelleDocumenti,
    isFetched: isFetchedCartelleDocumenti,
  } = useQuery(
    ['cartelle_documenti', dataDettaglioPratica],
    () =>
      // {dataDettaglioPratica && isFetchedDettaglioPratica (
      getFoldersTree(dataDettaglioPratica[0].entry.id),
    // )
  )

  const {
    data: dataListaDocumenti,
    isFetching: isFetchingListaDocumenti,
    isLoading: isLoadingListaDocumenti,
    isFetched: isFetchedListaDocumenti,
    refetch: refetchListaDocumenti,
  } = useQuery(
    ['lista_documenti', idDocumenti, refreshListaDocValue],
    () => {
      if (idDocumenti) return getFoldersTree(idDocumenti)
    },
    { refetchInterval: refetchInterval },
  )

  const { data } = useQuery(
    ['GetEsitoADV'],
    () => getEsitoADV(dettaglioPratica.entry.id),
    { enabled: statoRichiesta === StatiPratica.chiusa },
  )


  useEffect(() => {
    if (fileToDownload) {
      const linkSource = `data:application/pdf;base64,${fileToDownload.document}`
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = fileToDownload.title
      downloadLink.click()
    }
  }, [fileToDownload])

  useEffect(() => {
    //debugger
    if (
      indicatoriPratica &&
      indicatoriPratica.indicatori !== undefined &&
      indicatoriPratica.indicatori !== null &&
      isFetchedIndicatoriPratica === true
    ) {
      setDataNotAvailable(false)
    }
  }, [indicatoriPratica])

  //sync statoRichiesta
  useEffect(() => {
    syncStatoRichiesta(statoRichiesta)
  }, [statoRichiesta])

  useEffect(() => {
    if (
      dataDettaglioPratica &&
      dataDettaglioPratica.length > 0 &&
      dataDettaglioPratica[0].entry &&
      dataDettaglioPratica[0].entry.properties[
      'folder_model:statorichiesta'
      ] !== ''
    ) {
      setStatoRichiesta(
        dataDettaglioPratica[0].entry.properties['folder_model:statorichiesta'],
      )
      setTipoInserimento(
        dataDettaglioPratica[0].entry.properties[
        'folder_model:tipoInserimento'
        ],
      )
      if (dataDettaglioPratica[0].entry.properties['folder_model:richiestaSupporto'] === true) {
        localStorage.setItem("richiestaSupporto", true)
      } else {
        localStorage.setItem("richiestaSupporto", false)
      }
    }
  }, [dataDettaglioPratica])

  //il parametro "refetchPostUpdateFolder" viene passato al salva
  //dal modale come true
  //successivamente lo useEffect agirà da switch per fare il refetch dell'alberatura.
  useEffect(() => {
    //debugger
    // console.log(refetchPostUpdateFolder)
    if (refetchPostUpdateFolder === true) {
      setRefetchInterval(1000)
      setTimeout(function () {
        setRefetchInterval(0)
        setRefetchPraticheAccordion(false)
      }, 4000)
    }
  }, [refetchPostUpdateFolder])
  const [refetchSezioneGialla, setRefetchSezioneGialla] = useState(false)
  const syncRefetchSezioneGialla = ref => {
    //debugger
    // console.log(refetchSezioneGialla)
    setRefetchSezioneGialla(ref)
    // console.log(refetchSezioneGialla)
  }
  useEffect(() => {
    //debugger
    // console.log(refetchSezioneGialla)
    if (refetchSezioneGialla === true) {
      setRefetchIntervalSezioneGialla(1000)
      setTimeout(function () {
        setRefetchIntervalSezioneGialla(0)
        setRefetchSezioneGialla(false)
      }, 4000)
    }
  }, [refetchSezioneGialla])
  useLayoutEffect(() => {
    //debugger
    var a = dettaglioPratica.entry.properties['folder_model:NDG']
    // console.log(StatiPratica.checkOutOperatore)
    // console.log(statoRichiesta)

    if (
      statoRichiesta === StatiPratica.inRichiesta ||
      statoRichiesta === StatiPratica.inLavorazione
    ) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  }, [dettaglioPratica.entry.properties['folder_model:NDG']])

  useEffect(() => {
    try {
      getApprovazioneADV(idNodoPratica).then(response => {
        // console.log(response)
        setNoteUtenteAutorizzativo(response?.noteUtente)
        // console.log('Response note utente = ', response?.noteUtente)
      })
    } catch (error) {
      // console.log(error)
    }
  }, [idNodoPratica])

  const openTable = event => {
    //debugger
    event.preventDefault()
    event.stopPropagation()
    openAdvAutomatica(openTableState)
  }

  const refreshListaDocAndStatoRichiesta = () => {
    //debugger
    refreshListaDoc()
    getNodeChildById(idNodoPratica).then(child => {
      if (
        child &&
        child[0].entry &&
        child[0].entry.properties['folder_model:statorichiesta'] !== ''
      ) {
        setStatoRichiesta(
          child[0].entry.properties['folder_model:statorichiesta'],
        )
      }
    })
    //window.location.reload()
  }

  const handleOpenMenu = documentId => {
    //debugger
    if (!documentsToView.includes(documentId)) {
      setDocumentsToView(oldDocuments => [...oldDocuments, documentId])
    } else {
      setDocumentsToView(oldDocuments =>
        oldDocuments.filter(docId => docId !== documentId),
      )
    }
  }




  // Funzioni per aprire e chiudere il modale
  const handleOpenModal = () => {
    //debugger
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    //debugger
    setOpenModal(false);
  };

  const handleConfirmClosePratica = () => {
    //debugger
    // Esegui qui la chiusura della pratica
    closePratica(idNodoPratica)
      .then(response => {
        refetchPraticheDettaglio();
        window.location.reload(true);
      })
      .catch(response => {
        // Gestisci eventuali errori
      });
    setOpenModal(false); // Chiudi il modale
  };



  const handleChangeSintesi = event => {
    event.preventDefault()
    setSintesi(event.target.value)
  }

  const handleChange = panel => (event, newExpanded) => {
    //debugger
    setExpanded(newExpanded ? panel : false)
  }

  const handleChangeDet = panel => (event, newExpandedDet) => {
    //debugger
    setExpandedDet(newExpandedDet ? panel : false)
  }
  // check permessi da backend per upload documenti in differenti cartelle
  const uploadPermission = folderName => {
    // console.log(folderName)
    switch (folderName) {
      case "Documenti d'identità":
        if (isValidUser('UPLOAD_DOCUMENTI_IDENTITA')) {
          return false
        } else {
          return true
        }
      case 'Documenti per adeguata verifica': {
        if (isValidUser('UPLOAD_DOCUMENTI_ADV')) {
          return false
        } else {
          return true
        }
      }
      case 'Documenti Banca':
        if (isValidUser('UPLOAD_DOCUMENTI_BANCA')) {
          return false
        } else {
          return true
        }
      default:
        return false
    }
  }

  const getPdf = event => {
    try {
      //debugger
      // console.log(StatiPratica.checkOutOperatore)
      // console.log(StatiPratica)
      //debugger
      setLoading(true)
      event.preventDefault()
      event.stopPropagation()
      getRiepilogoPdf(idNodoPratica).then(res => {
        if (res.data.code === 200) {
          OpenPrintPreviewFromByteArray(res.data.body)
          setLoading(false)
        } else setLoading(false)
      })
    } catch (error) {
      alert(error)
      setLoading(false)
    }
  }



  let azioniDaPorreInEssereUnique = []
  let azioniDaPorreInEssere
  let tooltipStatoPraticaChiusa =
    "Cliente a cui è stato assegnato uno score factor 'giallo' all’interno del GRC per il quale in corso ulteriori approfondimenti condotti in data " +
    format(
      new Date(dettaglioPratica.entry?.properties['folder_model:dataChiusura']),
      'dd/MM/yyyy',
    )
  let finalParteTooltip = ''
  if (sezioneGiallaSalvata) {
    azioniDaPorreInEssere = sezioneGiallaSalvata?.azioniDaPorreInEssere
    azioniDaPorreInEssereUnique = azioniDaPorreInEssere
    if (sezioneGiallaSalvata?.checkoutOperatore === "Sono emersi elementi tali da rendere necessaria una attività di adeguata verifica rafforzata" ||
      sezioneGiallaSalvata?.checkoutOperatore === "E' stata rilevata la presenza di un PIL- Politico Italiano Locale") {
      finalParteTooltip = sezioneGiallaSalvata?.checkoutOperatore[0]
    }
  } else {
    azioniDaPorreInEssere = data?.azioniDaPorreInEssere
    azioniDaPorreInEssereUnique = azioniDaPorreInEssere
    if (data?.checkoutOperatore === "Sono emersi elementi tali da rendere necessaria una attività di adeguata verifica rafforzata" ||
      data?.checkoutOperatore === "E' stata rilevata la presenza di un PIL- Politico Italiano Locale") {
      finalParteTooltip = data?.checkoutOperatore[0]
    }
  }

  if (Array.isArray(azioniDaPorreInEssere)) {
    azioniDaPorreInEssereUnique = [...new Set(azioniDaPorreInEssere)]
  }

  tooltipStatoPraticaChiusa =
    tooltipStatoPraticaChiusa + ' ' + finalParteTooltip

  const isPDF = (fileName) => {
    return fileName.toLowerCase().endsWith('.pdf');
  };

  return (
    <Accordion
      elevation={0}
      disableGutters
      square={true}
      sx={{
        width: '100%',
        backgroundColor: theme.palette.grey.dark,
        size: '70%',
        ml: '20px',
      }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: theme.palette.grey.dark,
        }}
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid
          container
          display="contents"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Stack sx={{ width: '9%' }} alignItems="center">
            <Typography
              sx={{ fontWeight: 900, textAlign: 'center' }}
              variant="standard"
            >
              {`NDG`}
            </Typography>

            <Typography sx={{ textAlign: 'center' }} variant="caption">
              {valueNDGModal
                ? valueNDGModal
                : dettaglioPratica.entry.properties['folder_model:NDG']}
            </Typography>
          </Stack>
          <Stack
            sx={{ width: '20%' }}
            alignItems="center"
            justifyItems={'start'}
          >
            <Typography
              sx={{ fontWeight: 900, textAlign: 'center' }}
              variant="caption"
            >{`ADEGUATA VERIFICA DEL `}</Typography>
            <Typography variant="caption">
              {`${format(
                new Date(dettaglioPratica.entry.createdAt),
                'dd/MM/yyyy',
              )}`}
            </Typography>
          </Stack>
          <Stack sx={{ width: '20%' }} alignItems="center">
            <Typography
              sx={{ fontWeight: 900, textAlign: 'center' }}
              variant="caption"
            >
              {`TIPO INSERIMENTO`}
            </Typography>

            <Typography sx={{ textAlign: 'center' }} variant="caption">
              {
                dettaglioPratica.entry.properties[
                'folder_model:tipoInserimento'
                ]
              }
            </Typography>
          </Stack>

          <Stack sx={{ width: '27%' }} alignItems="center">
            <Typography
              sx={{ fontWeight: 900, textAlign: 'center' }}
              variant="caption"
            >
              {`MOTIVAZIONI DELL'ADEGUATA VERIFICA`}
            </Typography>
            {dettaglioPratica.entry.properties[
              'folder_model:tipoInserimento'
            ] === 'Automatica Alto Rischio' ? (
              <IconButton disabled={dataNotAvailable} onClick={openTable}>
                <VisibilityIcon />
              </IconButton>
            ) : dettaglioPratica.entry.properties[
              'folder_model:tipoInserimento'
            ] === 'Automatica Medio Rischio' || '' ? (
              <IconButton disabled={dataNotAvailable} onClick={openTable}>
                <VisibilityIcon />
              </IconButton>
            ) : dettaglioPratica?.entry?.properties[
              'folder_model:relazione'
            ]?.toLowerCase() == 'altro' ||
              dettaglioPratica?.entry?.properties[
                'folder_model:relazione'
              ]?.toLowerCase() == 'esito monitoraggio' ? (
              <div>

                <Accordion
                  expanded={expandedDet === 'panel1'}
                  onChange={handleChangeDet('panel1')}
                  style={
                    expandedDet
                      ? {
                        minWidth: 200,
                        maxWidth: 100,
                        backgroundColor: 'rgba(255,255,255,0.3)',
                      }
                      : {
                        minWidth: 200,
                        maxHeight: 25,
                        maxWidth: 100,
                        backgroundColor: 'rgba(255,255,255,0.3)',
                      }
                  }
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowDropDownIcon
                        sx={
                          expandedDet
                            ? {}
                            : { position: 'relative', bottom: 10 }
                        }
                      />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography
                      sx={
                        expandedDet
                          ? { fontSize: 12 }
                          : { position: 'relative', bottom: 10, fontSize: 12 }
                      }
                    >
                      {
                        dettaglioPratica.entry.properties[
                        'folder_model:relazione'
                        ]
                      }
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {dettaglioPratica.entry.properties['folder_model:dettaglioRelazione'] &&

                      <div >
                        <Tooltip title={dettaglioPratica?.entry?.properties[
                          'folder_model:dettaglioRelazione'
                        ]}>
                          <Typography sx={{
                            fontSize: 12, maxWidth: 130,
                            overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold'
                          }}>
                            {`Dettaglio relazione`}
                            {
                              // dettaglioPratica?.entry?.properties[
                              // 'folder_model:dettaglioRelazione'
                              // ]
                            }
                          </Typography>
                        </Tooltip>
                      </div>}

                  </AccordionDetails>
                </Accordion>
              </div>
            ) : (
              <div>

                {dettaglioPratica?.entry?.properties['folder_model:relazione'] && (
                  <Accordion
                    expanded={expandedDet === 'panel1'}
                    onChange={handleChangeDet('panel1')}
                    style={
                      expandedDet
                        ? {
                          minWidth: 200,
                          maxWidth: 100,
                          backgroundColor: 'rgba(255,255,255,0.3)',
                        }
                        : {
                          minWidth: 200,
                          maxHeight: 150,
                          maxWidth: 100,
                          backgroundColor: 'rgba(255,255,255,0.3)',
                        }
                    }
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowDropDownIcon
                          sx={
                            expandedDet
                              ? {}
                              : { position: 'relative', bottom: 10 }
                          }
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ textAlign: 'center' }} variant="caption">
                        {valueRelazioneModal
                          ? valueRelazioneModal
                          : dettaglioPratica.entry.properties['folder_model:relazione']}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {(dettaglioPratica.entry.properties['folder_model:relazione'] && dettaglioPratica.entry.properties['folder_model:dettaglioRelazione']) &&
                        <div>
                          <Tooltip title={dettaglioPratica?.entry?.properties[
                            'folder_model:dettaglioRelazione'
                          ]}>
                            <Typography sx={{
                              fontSize: 12, maxWidth: 130, fontWeight: 'bold',
                              overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                            }}>
                              {`Dettaglio relazione`}

                              {
                                // dettaglioPratica?.entry?.properties[
                                // 'folder_model:dettaglioRelazione'
                                // ]
                              }
                            </Typography>
                          </Tooltip>
                        </div>}

                    </AccordionDetails>
                  </Accordion>)}
              </div>



            )}
          </Stack>
          <Stack sx={{ width: '20%' }}>
            <Typography
              sx={{ fontWeight: 900, textAlign: 'center' }}
              variant="caption"
            >
              {`STATO PRATICA`}
            </Typography>

            {statoRichiesta === StatiPratica.chiusa &&
              dettaglioPratica.entry?.properties['folder_model:dataChiusura'] ? (
              dettaglioPratica?.entry?.properties[
                'folder_model:tipoInserimento'
              ] === 'Automatica Medio Rischio' ? (
                <Tooltip title={tooltipStatoPraticaChiusa}>
                  <Typography sx={{ textAlign: 'center' }} variant="caption">
                    {statoRichiesta +
                      ' il ' +
                      format(
                        new Date(
                          dettaglioPratica.entry?.properties[
                          'folder_model:dataChiusura'
                          ],
                        ),
                        'dd/MM/yyyy',
                      )}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography sx={{ textAlign: 'center' }} variant="caption">
                  {statoRichiesta +
                    ' il ' +
                    format(
                      new Date(
                        dettaglioPratica.entry?.properties[
                        'folder_model:dataChiusura'
                        ],
                      ),
                      'dd/MM/yyyy',
                    )}
                </Typography>
              )
            ) : (
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {statoRichiesta}
              </Typography>
            )}
          </Stack>
          {statoRichiesta === StatiPratica.chiusa && (
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography
                sx={{ fontWeight: 900, textAlign: 'center' }}
                variant="caption"
              >
                {`AZIONI DA PORRE IN ESSERE`}
              </Typography>
              {azioniDaPorreInEssereUnique?.map((azione, index) => (
                <Tooltip
                  key={index}
                  title={azione}
                  style={{ marginTop: '2px', maxWidth: '180px' }}
                >
                  <Chip
                    sx={{
                      '& .MuiChip-root': {
                        borderRadius: 0,
                      },
                    }}
                    size="small"
                    label={azione}
                    key={azione}
                  />
                </Tooltip>
              ))}
            </Stack>
          )}
        </Grid>
        {showButton && (
          <Stack sx={{ width: '9%', }}>
            {loading ? (
              <div style={{ marginLeft: '20px', marginTop: '1px' }}>
                <CircularProgress />
              </div>
            ) : (
              <Tooltip key={'Stampa Riepilogo'} title="Stampa Riepilogo">
                <IconButton onClick={getPdf}>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {statoRichiesta === StatiPratica.daApprovare ||
          statoRichiesta === StatiPratica.chiusa ? (
          <OnlyWithFunction acceptedFunction="SALVA">
            <Accordion elevation={0} disableGutters square={true}>
              <AccordionSummary
                sx={{
                  backgroundColor: theme.palette.blue.dark,
                  width: '100%',
                  color: 'white',
                }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                APPROVAZIONE ADEGUATA VERIFICA
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: theme.palette.blue.light,

                }}
              >
                <Stack >
                  {statoRichiesta === StatiPratica.daApprovare && (
                    <ApprovazioneAdeguataVerifica
                      idNodoPratica={idNodoPratica}
                    />
                  )}
                  {statoRichiesta !== StatiPratica.daApprovare &&
                    statoRichiesta === StatiPratica.chiusa && (
                      <ApprovazioneAdeguataVerificaSalvata
                        idNodoPratica={idNodoPratica}
                      />
                    )}
                </Stack>
              </AccordionDetails>



            </Accordion>
          </OnlyWithFunction>
        ) : null}
        {(statoRichiesta === StatiPratica.checkOutOperatore ||
          statoRichiesta === StatiPratica.chiusa ||
          statoRichiesta === StatiPratica.daApprovare ||
          statoRichiesta === StatiPratica.infoComplete) && (
            <Accordion elevation={0} disableGutters square={true}>
              <AccordionSummary
                sx={{
                  backgroundColor: theme.palette.yellow.middle,
                  width: '100%',
                }}
                onClick={() => {
                  setStatoRichiesta(statoRichiesta)
                }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                ADEGUATA VERIFICA
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: theme.palette.yellow.middle,
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                {(statoRichiesta === StatiPratica.checkOutOperatore ||
                  statoRichiesta === StatiPratica.infoComplete ||
                  statoRichiesta === StatiPratica.inAttesaOpzionali) &&
                  tipoInserimento !== 'Automatica Medio Rischio' && tipoInserimento !== 'Manuale Medio Rischio' && (
                    <EsitoAdeguataVerifica
                      syncRefetchSezioneGialla={syncRefetchSezioneGialla}
                      idNodoPratica={idNodoPratica}
                      disabled={!dataAbilitazioneTastoChiuso}
                      statoRichiesta={statoRichiesta}
                      tipoInserimento={tipoInserimento}
                      sezioneGiallaSalvata={
                        sezioneGiallaSalvata ? sezioneGiallaSalvata : ''
                      }
                      attivitaSvolteList={attivitaSvolteList}
                    />
                  )}
                {(statoRichiesta === StatiPratica.checkOutOperatore ||
                  statoRichiesta === StatiPratica.infoComplete ||
                  statoRichiesta === StatiPratica.inAttesaOpzionali) &&
                  (tipoInserimento === 'Automatica Medio Rischio' || tipoInserimento === 'Manuale Medio Rischio') && (
                    <EsitoAdeguataVerificaMedioR
                      syncRefetchSezioneGialla={syncRefetchSezioneGialla}
                      idNodoPratica={idNodoPratica}
                      statoRichiesta={statoRichiesta}
                      tipoInserimento={tipoInserimento}
                      disabled={!dataAbilitazioneTastoChiuso}
                      sezioneGiallaSalvata={
                        sezioneGiallaSalvata ? sezioneGiallaSalvata : ''
                      }
                    />
                  )}
                {statoRichiesta !== StatiPratica.checkOutOperatore &&
                  statoRichiesta !== StatiPratica.infoComplete &&
                  (tipoInserimento !== 'Automatica Medio Rischio' && tipoInserimento !== 'Manuale Medio Rischio') && (
                    <EsitoAdeguataVerificaSalvata idNodoPratica={idNodoPratica} />
                  )}
                {statoRichiesta !== StatiPratica.checkOutOperatore &&
                  statoRichiesta !== StatiPratica.infoComplete &&
                  (tipoInserimento === 'Automatica Medio Rischio' || tipoInserimento === 'Manuale Medio Rischio') && (
                    <EsitoAdeguataVerificaMedioR
                      syncRefetchSezioneGialla={syncRefetchSezioneGialla}
                      idNodoPratica={idNodoPratica}
                      statoRichiesta={statoRichiesta}
                      tipoInserimento={tipoInserimento}
                      sezioneGiallaSalvata={
                        sezioneGiallaSalvata ? sezioneGiallaSalvata : ''
                      }
                    />
                  )}
              </AccordionDetails>
            </Accordion>
          )}

        {dettaglioPratica.entry?.properties[
          'folder_model:statoApprovazione'
        ] === true && (
            <Stack>
              <Typography variant="caption">NOTE UTENTE AUTORIZZATIVO</Typography>
              <Tooltip
                key={'NOTE UTENTE AUTORIZZATIVO'}
                title="NOTE UTENTE AUTORIZZATIVO"
              >
                <textarea
                  style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }}
                  minows={3}
                  defaultValue={noteUtenteAutorizzativo}
                  disabled={true}
                ></textarea>
              </Tooltip>
            </Stack>
          )}
        <Accordion square disableGutters elevation={0}>
          {isFetchingDettaglioPratica && <CircularProgress />}
          {!isFetchingDettaglioPratica && dataDettaglioPratica && dataDettaglioPratica.length > 0 && dataDettaglioPratica[0]?.entry?.name && (
            <>
              <AccordionSummary
                sx={{
                  backgroundColor: theme.palette.grey.dark,
                  width: '100%',
                }}
                onClick={() => {
                  setStatoRichiesta(statoRichiesta)
                }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >{isFetchingDettaglioPratica && <CircularProgress />}
                {dataDettaglioPratica && dataDettaglioPratica[0]?.entry?.name}
              </AccordionSummary>
            </>
          )}


          <AccordionDetails sx={{ backgroundColor: theme.palette.grey.dark }}>
            <AccordionDetails sx={{ backgroundColor: theme.palette.grey.dark }}>
              {isFetchingCartelleDocumenti && <CircularProgress />}
              {isFetchedCartelleDocumenti &&
                dataCartelleDocumenti &&
                dataCartelleDocumenti.map(cartella => (
                  <Accordion
                    key={cartella.id}
                    expanded={expanded === cartella.id}
                    onChange={handleChange(cartella.id)}
                    id={cartella.id}
                    square
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary
                      sx={{
                        backgroundColor: theme.palette.grey.dark,
                        width: '100%',
                      }}
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1a-content"
                      onClick={() => setIdDocumenti(cartella.id)}
                      id="panel1a-header"
                    >
                      {cartella.name}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: theme.palette.grey.dark,
                      }}
                    >
                      <List>
                        {isFetchingListaDocumenti && <CircularProgress />}
                        {isFetchedListaDocumenti &&
                          dataListaDocumenti?.map(documento => (
                            <><ListItem
                              //sx={{ zIndex: 1600, position: 'relative' }}
                              key={documento.id}
                              secondaryAction={
                                <Box bgcolor={'black'}>
                                  <UploadDocument
                                    //sx={{ zIndex: 1200 }}
                                    disabled={statoRichiesta === StatiPratica.chiusa ||
                                      uploadPermission(cartella.name)}
                                    key={documento.id}
                                    idDocumento={documento.id}
                                    onUpload={refreshListaDocAndStatoRichiesta} />
                                </Box>}>
                              {documento.nodeIsOptional === true && (
                                <DeleteDocument
                                  key={`${documento.id}erase`}
                                  disabled={statoRichiesta ===
                                    StatiPratica.daApprovare ||
                                    statoRichiesta === StatiPratica.chiusa ||
                                    (isValidUser('UPLOAD_DOCUMENTI_ADV') ===
                                      false &&
                                      isValidUser(
                                        'DELETE_DOCUMENTI_ADV_OPZIONALI'
                                      ) === false)}
                                  idFile={documento.id}
                                  fileName={documento.name}
                                  onDelete={refreshListaDocAndStatoRichiesta} />
                              )}
                              <Tooltip
                                title={(documento.name === 'Richiesta AG UIF'
                                  ? titlesToolTip.RichiestaAgUif
                                  : '') ||
                                  (documento.name === 'Controlli AML e Gianos'
                                    ? titlesToolTip.ControlliAmleGianos
                                    : '')}
                              >
                                <ListItemText
                                  key={documento.name}
                                  primary={/*documento.name.length > 40 ? `${documento.name.substring(0, 42)}...` :*/ documento.name}
                                  sx={{
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                  }}
                                />
                              </Tooltip>
                            </ListItem>
                              <Stack
                                direction={'row'}
                                spacing={-2}
                              >
                                {documento.children &&
                                  documento.children.length > 0 &&
                                  documento.children
                                    .filter(child => child.nodeIsFile)
                                    .map(file => (
                                      <>
                                        <ListItem
                                          key={'subDirectories'}
                                          direction={'row'}
                                          spacing={-2}
                                        /*sx={{ zIndex: 16000 }}*/
                                        >
                                          {documentsToView.includes(
                                            file.id
                                          ) ? (
                                            <><Tooltip title={`${file.name}`}>
                                              <IconButton
                                                onClick={() => handleOpenMenu(file.id)}
                                              >
                                                <SummarizeIcon sx={{
                                                  /*zIndex: 1600,*/
                                                  margin: '8px',
                                                  "& > *": { margin: '-4px' },
                                                  marginRight: '18px',
                                                  "& > **": { margin: '-14px' }
                                                }} />
                                              </IconButton>
                                            </Tooltip>
                                              <Stack
                                                direction="row"
                                                sx={{ /*zIndex: 1600,*/ margin: '-8px', "& > *": { margin: '4px' } }}
                                              >
                                                <Tooltip
                                                  key={`${file.name}`}
                                                  title={`${file.name}`}
                                                >
                                                  <IconButton
                                                    aria-label="View Document"
                                                    key={`${file.id}erase`}
                                                    onClick={(event) => clickDoc(event, file.id, file.name)}
                                                    disabled={!isPDF(file.name)} // disabilita il bottone file nn PDF
                                                  >
                                                    <VisibilityIcon
                                                      sx={{ /*zIndex: 1600,*/ margin: '8px', "& > *": { margin: '4px' } }} />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                  title={`${file.name}`}
                                                >
                                                  <IconButton
                                                    key={file.id}
                                                    onClick={event => handleDownloadFile(event, file.id, file.name)}

                                                  >
                                                    <FileDownloadIcon
                                                      sx={{/* zIndex: 1600, */margin: '8px', "& > *": { margin: '4px' } }} />
                                                  </IconButton>
                                                </Tooltip>
                                                <DeleteDocument
                                                  sx={{ /*zIndex: 1600, */margin: '8px', "& > *": { margin: '4px' } }}
                                                  key={`${file.id}erase`}
                                                  disabled={statoRichiesta ===
                                                    StatiPratica.daApprovare ||
                                                    statoRichiesta ===
                                                    StatiPratica.chiusa ||
                                                    uploadPermission(
                                                      cartella.name
                                                    )}
                                                  idFile={file.id}
                                                  fileName={file.name}
                                                  onDelete={refreshListaDocAndStatoRichiesta} />
                                              </Stack>

                                            </>
                                          ) : (
                                            <Tooltip title={`${file.name}`}>
                                              <IconButton
                                                onClick={() => handleOpenMenu(file.id)}
                                              >
                                                <SummarizeIcon />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </ListItem>
                                      </>
                                    ))}

                              </Stack></>
                          ))}
                        {isFetchedListaDocumenti &&
                          cartella.name !== 'Documenti per adeguata verifica' &&
                          statoRichiesta !== StatiPratica.daApprovare &&
                          statoRichiesta !== StatiPratica.chiusa && (
                            <Stack sx={{ m: 2 }} direction={'row'}>
                              <Typography sx={{ mr: 1, mb: -2, mt: 0.3 }}>
                                Ulteriore documentazione ove presente
                              </Typography>

                              <TextFieldCreateFolder
                                folderId={cartella.id}
                                onSubmit={refreshListaDoc}
                                listaDocs={dataListaDocumenti}
                              ></TextFieldCreateFolder>
                            </Stack>
                          )}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </AccordionDetails>
          </AccordionDetails>
          {(statoRichiesta === StatiPratica.inRichiesta ||
            statoRichiesta === StatiPratica.inLavorazione) && (
              <OnlyWithFunction acceptedFunction="CHIUDI">
                <AccordionActions
                  sx={{
                    backgroundColor: theme.palette.grey.dark,
                    width: '100%',
                    alignItems: 'center',
                    padding: 0,
                  }}
                >
                  <StyledButton
                    disabled={
                      isValidUser('CHIUDI') === false ||
                      statoRichiesta === StatiPratica.daApprovare ||
                      statoRichiesta === StatiPratica.chiusa
                    }
                    onClick={handleOpenModal} // Qui apri il modale invece di chiamare direttamente handleSubmit
                    sx={{
                      backgroundColor: theme.palette.yellow.dark,
                      borderRadius: '5px 0px 0px 5px',
                      border: 'none',
                    }}
                  >
                    Chiudi
                  </StyledButton>

                  <Dialog
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Conferma chiusura pratica"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Sei sicuro di voler chiudere questa pratica? Questa azione non può essere annullata.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleConfirmClosePratica} color="primary" autoFocus>
                        Conferma
                      </Button>
                      <Button onClick={handleCloseModal} color="primary">
                        Annulla
                      </Button>

                    </DialogActions>
                  </Dialog>

                </AccordionActions>
              </OnlyWithFunction>
            )}
        </Accordion>
        <EvidenzaEsitoMonitoraggio idAdv={idNodoPratica}></EvidenzaEsitoMonitoraggio>


      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionDettaglio
